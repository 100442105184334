<template>
    <el-dialog
            title="权限设置"
            :close-on-click-modal="false"
            v-dialogDrag
            width="350px"
            :visible.sync="visible">
        <el-form size="small" class="auth" :model="inputForm" status-icon v-loading="loading" ref="inputForm"
                 @keyup.enter.native="inputFormSubmit()"
                 @submit.native.prevent>
            <el-tabs type="border-card">
                <el-tab-pane>
                    <span slot="label"><i class="fa fa-navicon"></i> 菜单权限</span>
                    <el-scrollbar style="height: 450px">
                        <el-tree
                                :data="menuList"
                                :props=" {
                                label: 'name',
                                children: 'children'
                              }"
                                node-key="id"
                                ref="menuListTree"
                                show-checkbox>
                        </el-tree>
                    </el-scrollbar>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button v-if="!loading" type="primary" icon="el-icon-check" size="small" plain @click="inputFormSubmit()"
                 v-noMoreClick>保存</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                title: '',
                menuList: [],
                menuCheckedKeys: [],
                dataRuleCheckedKeys: [],
                inputForm: {
                    id: '',
                    menuIds: '',
                    dataRuleIds: '',
                    oldName: '',
                    oldEname: ''
                }
            }
        },
        methods: {
            init(id) {
                this.inputForm.id = id
                this.visible = true
                this.$nextTick(() => {
                    this.$refs.inputForm.resetFields()
                    this.$refs.menuListTree.setCheckedKeys([])
                    let p1 = this.$axios(this.api.auth.sysnewmenuList, {}, 'get')
                    if (this.inputForm.id) {
                        this.loading = true
                        let p2 = this.$axios(this.api.auth.getById, {roleId: this.inputForm.id}, 'get')
                        Promise.all([p1, p2]).then((result) => {
                            this.menuList = result[0].data
                            let data = result[1]
                            if (data.status) {
                                this.loading = false
                                let that = this
                                setTimeout(function () {
                                    var menuIds = data.data.menuIds.split(',')
                                    menuIds.forEach(value => {
                                        that.$refs.menuListTree.setChecked(value,true,false)
                                    })
                                })
                            }
                        })
                    } else {
                        Promise.all([p1]).then((result) => {
                            this.menuList = result[0].data.treeData
                            this.loading = false
                        })
                    }
                })
            },
            // 表单提交
            inputFormSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    this.inputForm.menuIds = this.$refs.menuListTree.getCheckedKeys().concat(this.$refs.menuListTree.getHalfCheckedKeys()).join(',')
                    // this.inputForm.dataRuleIds = this.$refs.dataRuleTree.getCheckedKeys().concat(this.$refs.dataRuleTree.getHalfCheckedKeys()).join(',')
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.auth.sysrolemenuSave, {roleId: this.inputForm.id, menuIds: this.inputForm.menuIds}, 'post').then(data => {
                            this.loading = false
                            if (data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .el-tree {
        min-width: 100%;
        display: inline-block !important;
    }

    .auth {
        margin-top: -30px;
        margin-right: -19px;
        margin-bottom: -30px;
        margin-left: -19px;
    }

    .el-scrollbar {
        height: 100%;
    }

</style>
